<template>
  <li class="container flex s_start">
    <router-link
      :to="path"
      class="cover-img"
      :title="houseItem.title"
      target="_blank"
    >
      <img
        v-if="houseItem.coverImage"
        class="all"
        :src="houseItem.coverImage"
      />
      <img
        v-else
        class="all"
        :src="defaultCover"
      />
      <img
        v-if="houseItem.vrUrl"
        class="vr-logo"
        src="@/assets/imgs/icon_vr_gold.gif"
      />
    </router-link>
    <div
      class="info flex1"
      style="margin-left:30px"
    >
      <div class="flex s_center">
        <router-link
          :to="path"
          class="info-title ellipsis size22 black bold"
          style="max-width:495px"
          target="_blank"
        >{{houseItem.title}}</router-link>
        <span
          v-show="houseItem.tag.includes('必看好房')&&houseItem.tag.includes('特惠好房')!=-1&&houseItem.tag.includes('礼包房源')!=-1"
          class="good-house"
        >必看好房</span>
        <span
          v-show="houseItem.tag.includes('特惠好房')"
          class="good-house"
        >特惠好房</span>
        <span
          v-show="houseItem.tag.includes('礼包房源')"
          class="good-house"
        >礼包房源</span>
      </div>
      <div class="mt20 col66 relative">
        <!-- <div class="flood flex s_center">
                    <i class="el-icon-location-outline size16 mr10" />
                    <router-link to="/">{{houseItem.address}}</router-link>
                </div> -->
        <div
          class="flex s_center ellipsis"
          style="max-width:510px"
        >
          <i class="el-icon-s-home size16 mr10" />
          <span
            class="pr5"
            v-if="houseItem.currentFloor || houseItem.totalFloor"
          >{{houseItem.currentFloor || ""}}{{houseItem.totalFloor ? `共(${houseItem.totalFloor}层)`:''}}</span>
          <span
            class="pr5"
            v-if="houseItem.room || houseItem.hall"
          > | {{houseItem.room ? houseItem.room + "室" : ""}}{{houseItem.hall ? houseItem.hall + "厅" : ""}} </span>
          <span
            class="pr5"
            v-if="houseItem.buildingArea"
          > | {{houseItem.buildingArea}}平米</span>
          <span v-if="houseItem.orientation !== '未选择'"> | {{houseItem.orientation}}</span>
        </div>
        <ul
          v-if="houseItem.tag && houseItem.tag.length"
          class="flex wrap mt20"
        >
          <li
            v-for="tag in houseItem.tag"
            :key="tag"
            class="tag"
          >{{tag}}</li>
        </ul>
        <div class="price-info">
          <div class="total-price">
            <span :class="[(tradeType === 2 && houseItem.price ||  tradeType === 1 && houseItem.rentPrice)?'bold':'']">
              {{ tradeType === 2 ? houseItem.price/10000 || '暂无价格' : houseItem.rentPrice || '暂无价格' }}
            </span>
            <i v-if=" tradeType === 2 && houseItem.price ||  tradeType === 1 && houseItem.rentPrice">
              {{tradeType === 2 ? '万':'元/月'}}
            </i>
          </div>
          <div
            v-if="tradeType === 2 && houseItem.unitPrice"
            class="unit-price"
            v-html="transforPrice(houseItem.unitPrice)"
          ></div>
        </div>
      </div>

    </div>
    <div style="width:200px"></div>
  </li>
</template>
<script>
import Constant from "@/common/Constant";
import tool from "@/utils/tool";
export default {
  props: {
    tradeType: {
      type: Number,
      required: true,
    },
    houseItem: {
      type: Object,
      require: true,
    },
    agentGuid: {
      type: String,
    },
  },
  computed: {
    path() {
      let result = {
        path: "/shopHouseDetail/2",
        query: {
          guid: this.houseItem.guid,
          tradeType: this.tradeType,
          agentGuid: this.agentGuid ? this.agentGuid : "",
        },
      };

      return result;
    },
  },
  data() {
    return {
      defaultCover: Constant.DEFAULT_HOUSE,
    };
  },
  methods: {
    transforPrice(price) {
      return tool.transforNumber(price) + "元/平";
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding: 35px 0;
  border-bottom: 1px solid #f1f1f1;
  .cover-img {
    display: block;
    position: relative;
    width: 232px;
    height: 174px;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    .vr-logo {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 8px;
      bottom: 8px;
    }
  }
  .info {
    width: 640px;
    vertical-align: top;
    color: #616669;
    font-size: 14px;
    .info-title {
      &:hover {
        text-decoration: underline;
      }
    }
    .good-house {
      margin-left: 5px;
      padding: 0 5px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      line-height: 21px;
      height: 21px;
      background-color: #d53c3c;
    }
    .tag {
      color: #849aae;
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      padding: 0 11px;
      margin-right: 6px;
      margin-bottom: 6px;
      background-color: #f4f7f9;
    }
    .price-info {
      position: absolute;
      right: 0;
      top: 0;
      .total-price {
        text-align: right;
        font-size: 14px;
        color: #db4c3f;
        span {
          font-size: 26px;
          font-family: tahoma;
          margin-right: 5px;
        }
        i {
          margin-bottom: 2px;
          margin-right: 3px;
          font-style: normal;
        }
      }
      .unit-price {
        text-align: right;
        margin-top: 18px;
        width: 130px;
        font-size: 12px;
        color: #101d37;
        position: relative;
      }
    }
  }
}
</style>


